export class Sale {
    constructor() {
        this.sale_agent_name = ""
        this.email = ""
        this.password =  ""
        this.confirm_password = ""
        this.phone = ""
        this.full_name = ""
        this.address = ""
    }
  }
  