export class AgencyMember {
  constructor() {
      this.username = ""
      this.email = ""
      this.password =  ""
      this.confirm_password = ""
      this.phone = ""
      this.full_name = ""
      this.address = ""
  }
}
