export class Driver {
    constructor() {
        this.name = ""
        this.date_of_birth = ""
        this.cmnd =  ""
        this.phone = ""
        this.gender = ""
        this.license_type = ""
        this.license_number = ""
        this.address = ""
        this.license_expire_date = ""
        this.license_issue_date = ""
    }
  }
  