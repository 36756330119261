export const dataGPS = 
{
    "type": "LineString",
    "coordinates": [
        [
            105.744138,
            20.987483
        ],
        [
            105.74433,
            20.987549
        ],
        [
            105.744894,
            20.986893
        ],
        [
            105.745182,
            20.987108
        ],
        [
            105.745777,
            20.986681
        ],
        [
            105.746123,
            20.986422
        ],
        [
            105.746866,
            20.987011
        ],
        [
            105.747313,
            20.987342
        ],
        [
            105.747859,
            20.987818
        ],
        [
            105.748952,
            20.986573
        ],
        [
            105.749076,
            20.986512
        ],
        [
            105.750966,
            20.984431
        ],
        [
            105.75145,
            20.98388
        ],
        [
            105.751739,
            20.983484
        ],
        [
            105.751975,
            20.982959
        ],
        [
            105.752339,
            20.981769
        ],
        [
            105.752617,
            20.980828
        ]
    ]
}
