export class Agency {
  constructor() {
      this.agency_name = ""
      this.email = ""
      this.password =  ""
      this.phone = ""
      this.full_name = ""
      this.province_id = ""
  }
}
