import { th } from "date-fns/locale"

export class Staff {
    constructor() {
        this.email = ""
        this.password = ""
        this.phone = ""
        this.full_name = ""
        this.address = ""
        this.list_sale_agent_id = []
    }
}
